import React, { useRef, useEffect, useState } from 'react';
import IntroPanel from './IntroPanel';
import Header from './Header';
import ProgressBarContainer from './ProgressBarContainer';


function App() {
  const sectionRefs = useRef([]);
  sectionRefs.current = [];
  const [showProgressBar, setShowProgressBar] = useState(false);

  const addToRefs = (el) => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetType = entry.target.dataset.type;
            switch (targetType) {
              case 'progress-bar':
                setShowProgressBar(true);
                observer.unobserve(entry.target);
                break;
              case 'typing-effect':
                // Add the start-typing class to trigger the animation
                const typingContainer = entry.target.querySelector('.typing-effect-container');
                if (typingContainer) {
                  typingContainer.classList.add('start-typing');
                }
                break;
                case 'proficiency-bar':
                // Start proficiency bar animation
                const proficiencyBar = entry.target.querySelector('.proficiency-fill');
                if (proficiencyBar) {
                  const targetWidth = proficiencyBar.dataset.targetWidth;
                  proficiencyBar.style.width = targetWidth;
                }
                // Animate the proficiency label
                const proficiencyLabel = entry.target.querySelector('.text-sm.font-alliance-no1.text-gray-500');
                if (proficiencyLabel) {
                  proficiencyLabel.classList.add('label-surge');
                }
                break;

              default:
                entry.target.classList.add('line-grow');
            }
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    sectionRefs.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => sectionRefs.current.forEach((el) => observer.unobserve(el));
  }, []);



  return (
    <div>
      <IntroPanel />
      <Header />
      {/* Sections container */}
      <div className="relative">

        {/* Section 1 */}
        <div id='sec1' className='relative h-screen flex justify-left z-0 mx-auto w-full md:w-3/4'>
          <div className="absolute top-0 observed-line w-full md:w-full h-1 bg-transparent mt-[5%]" ref={addToRefs}></div>


          <div className="grid grid-rows-3 grid-cols-3 mt-[10%] w-full gap-y-60">

            <div className="col-span-2" ref={addToRefs} data-type="typing-effect">

              <div className='typing-effect-container'>
                <h1 className='font-alliance-no2 text-5xl font-extrabold whitespace-nowrap overflow-hidden'>Education</h1>
              </div>

              <div className="mt-8">
                <h1 className='text-2xl font-alliance-no1'>Bachelor in <span className='font-extrabold'>Computer Science</span> and <span className='font-extrabold'>Psychology</span></h1>
                <h1 className='text-2xl font-alliance-no1'>School: <span className='font-extrabold'>Iowa State University</span></h1>
                <h1 className='break-normal'>Location: Ames, Iowa USA</h1>
              </div>

            </div>

            <div ref={addToRefs} data-type="progress-bar">
              <div className='flex grid cols-2 justify-end'>
                <h1 className='font-alliance-no2 text-2xl font-extrabold whitespace-nowrap'>Progress to graduation:</h1>
                <ProgressBarContainer animate={showProgressBar} />
              </div>
            </div>

            <div className='flex justify-center'>
              <div>
                <h1 className='text-2xl font-alliance-no2 font-extrabold '>Relevant Coursework</h1>
                <div className='flex grid grid-rows justify-left py-8 gap-4 text-xl font-alliance-no1'>
                  <h1>Object Oriented Programming</h1>
                  <h1>Data Structures</h1>
                  <h1>Algorithms</h1>
                  <h1>Software Development Practices</h1>
                  <h1>Introduction to Computer Architecture & Machine Level Programming</h1>
                </div>

              </div>
            </div>

            <div className='flex justify-center'>
              <div>
                <h1 className='text-2xl font-alliance-no2 font-extrabold '>Programming Languages</h1>
                <div className='flex grid grid-rows justify-left py-8 gap-4 text-xl font-alliance-no1'>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>HTML</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="100%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>proficient</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>CSS</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="100%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>proficient</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>Javascript</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="51%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>familiar</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>Java</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="80%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>proficient</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>C/C++</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="66%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>familiar</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>C#</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="33%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>beginner</p>
                  </div>

                  <div ref={addToRefs} data-type='proficiency-bar'>
                    <h1>Python3</h1>
                    <div className="proficiency-fill bg-blue-500 h-0.5 mt-2" style={{ width: '0%' }} data-target-width="66%"></div>
                    <p className='text-sm font-alliance-no1 text-gray-500'>beginner</p>
                  </div>

                </div>

              </div>
            </div>

            <div className='flex justify-center'>
              <div>
                <h1 className='text-2xl font-alliance-no2 font-extrabold '>Frameworks & Tools</h1>
                <div className='flex grid grid-rows justify-left py-8 gap-4 text-xl font-alliance-no1'>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/react-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>React</h1>
                  </div>
                  
                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/tailwind-logo.png" alt="React Logo" className='w-[48px] mt-2.5'/>
                    <h1 className='ml-5'>Tailwindcss</h1>
                  </div>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/mui-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>MaterialUI</h1>
                  </div>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/git-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>Git</h1>
                  </div>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/mysql-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>MySql</h1>
                  </div>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/nodejs-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>Node.js</h1>
                  </div>

                  <div className='grid grid-cols-2 w-[128px] h-[50px]'>
                    <img src="/unity-logo.png" alt="React Logo" className='w-[48px]'/>
                    <h1 className='ml-5'>Unity</h1>
                  </div>

                </div>
              </div>
            </div>
            

          </div>

        </div>

        {/* Section 2 */}
        <div id='sec2' className="relative h-screen flex justify-left z-0 mx-auto w-full md:w-3/4">
          <div className="absolute top-0 observed-line w-full md:w-full h-1 bg-transparent mt-[5%]" ref={addToRefs}></div>

          <div className="relative mt-[10%] w-full" ref={addToRefs} data-type="typing-effect">
            <div className="typing-effect-container">
              <h1 className='font-alliance-no2 text-5xl font-extrabold whitespace-nowrap overflow-hidden'>About Me</h1>
            </div>
          </div>

        </div>

        {/* Section 3 */}
        <div id='sec3' className="relative h-screen flex justify-left z-0 mx-auto w-full md:w-3/4">
          <div className="absolute top-0 observed-line w-full md:w-full h-1 bg-transparent mt-[5%]" ref={addToRefs}></div>

          <div className="relative mt-[10%] w-full" ref={addToRefs} data-type="typing-effect">
            <div className="typing-effect-container">
              <h1 className='font-alliance-no2 text-5xl font-extrabold whitespace-nowrap overflow-hidden'>Projects</h1>
            </div>
          </div>

        </div>

        {/* Section 4 */}
        <div id='sec4' className="relative h-screen flex justify-left z-0 mx-auto w-full md:w-3/4">
          <div className="absolute top-0 observed-line w-full md:w-full h-1 bg-transparent mt-[5%]" ref={addToRefs}></div>

          <div className="relative mt-[10%] w-full" ref={addToRefs} data-type="typing-effect">
            <div className="typing-effect-container">
              <h1 className='font-alliance-no2 text-5xl font-extrabold whitespace-nowrap overflow-hidden'>Contact</h1>
            </div>
          </div>

        </div>


      </div>
    </div>
  );
}

export default App;
