import React, { useState, useEffect } from 'react';
import CircularProgressBar from './CircularProgressBar';
import AnimatedPercentage from './AnimatedPercentage';

const ProgressBarContainer = ({animate}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (animate) {
    const calculateProgress = () => {
      const startDate = new Date(2021, 7, 14); // JavaScript months are 0-indexed, so August is 7
      const endDate = new Date(2025, 4, 19); // May is 4
      const today = new Date();
      const totalDuration = endDate - startDate;
      const timeElapsed = today - startDate;
      const progressPercentage = (timeElapsed / totalDuration) * 100;
      return Math.min(Math.max(progressPercentage, 0.00), 100.00); // Ensures the progress is between 0 and 100
    };

    setProgress(calculateProgress());
  }
  }, [animate]);

  return (
    <div className='mt-11'>
      <AnimatedPercentage target={progress}/>
      <CircularProgressBar progress={progress} />
      
    </div>
  );
};

export default ProgressBarContainer;
