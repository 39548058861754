import React, { useEffect, useState } from 'react';

const CircularProgressBar = ({ progress }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const radius = 60;
  const circumference = 2 * Math.PI * radius;
  const initialOffset = circumference - (progress / 100) * circumference;

  const [grayOffset, setGrayOffset] = useState(circumference);

  useEffect(() => {
    if (progress > 0) {
      // Start the gray circle animation immediately
      setGrayOffset(0);
      // Start the green circle animation after a slight delay
      setTimeout(() => setStartAnimation(true), 50); // Delay in ms
    }
  }, [progress]);

  return (
    <svg width="240" height="140">
      {/* Gray background circle */}
      <circle
        className="progress-ring__background"
        stroke="lightgray"
        strokeWidth="4"
        fill="transparent"
        r={radius}
        cx="120"
        cy="120"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ 
          strokeDashoffset: grayOffset,
          transition: 'stroke-dashoffset 1s ease-out'
        }}
      />
      {/* Green progress circle */}
      <circle
        className="progress-ring__circle"
        stroke="#32de84"
        strokeWidth="4"
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ 
          strokeDashoffset: startAnimation ? initialOffset : circumference,
          transition: `stroke-dashoffset 1s ease-out ${startAnimation ? '0.25s' : '0s'}`
        }}
        fill="transparent"
        r={radius}
        cx="120"
        cy="120"
      />
    </svg>
  );
};

export default CircularProgressBar;
