import { useState, useEffect } from 'react';

function Header() {
  return (
<header className={`fixed top-0 z-50 left-0 w-full h-[12%] bg-white transition-transform`}>
  <div className="flex justify-between items-center w-full px-[12.5%] h-full">
    <div className="flex items-center">
      <a href="#" className='text-3xl py-1 font-alliance-no2 font-extrabold'>felipebe.dev</a>
      <nav className="flex space-x-4 ml-12 mt-3">
        <a href="#sec1" className="text-gray-500 font-alliance-no1 py-2 before:h-[1px] hover:before:scale-x-100 hover:before:origin-left relative 
        before:w-1/2 before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-black before:absolute before:left-0 before:top-0 ">
          Education
        </a>
        <a href="#sec2" className="text-gray-500 font-alliance-no1 py-2 before:h-[1px] hover:before:scale-x-100 hover:before:origin-left relative 
        before:w-1/2 before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-black before:absolute before:left-0 before:top-0 ">
          About Me
        </a>
        <a href="#sec3" className="text-gray-500 font-alliance-no1 py-2 before:h-[1px] hover:before:scale-x-100 hover:before:origin-left relative 
        before:w-1/2 before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-black before:absolute before:left-0 before:top-0 ">
          Projects
        </a>
        <a href="#sec4" className="text-gray-500 font-alliance-no1 py-2 before:h-[1px] hover:before:scale-x-100 hover:before:origin-left relative 
        before:w-1/2 before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-black before:absolute before:left-0 before:top-0 ">
          Contact
        </a>
      </nav>
    </div>

    {/* Logo container for GitHub and LinkedIn */}
    <div className="flex items-center space-x-4">
      <a href="https://github.com/felipebely" target="_blank" rel="noopener noreferrer">
        <img src="/github-mark/github-mark.png" alt="GitHub Logo" className="h-8 w-8" />
      </a>
      <a href="https://www.linkedin.com/in/felipebely/" target="_blank" rel="noopener noreferrer">
        <img src="/linkedin_logo_black.png" alt="LinkedIn Logo" className="h-8 w-8" />
      </a>
    </div>
  </div>
</header>

  );
}

export default Header;
