import React, { useEffect, useState } from 'react';

const AnimatedPercentage = ({ target }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue((prevValue) => {
        const updatedValue = prevValue + (target - prevValue) / 10;
        if (updatedValue > target) {
          clearInterval(interval);
          return target; // Ensure it doesn't exceed the target
        }
        return updatedValue;
      });
    }, 20); // Adjust the interval speed as needed

    return () => clearInterval(interval); // Cleanup on unmount
  }, [target]);

  return <div className='text-xl font-alliance-no1'>{currentValue.toFixed(2)}%</div>; // Display the number with two decimal places
};

export default AnimatedPercentage;
