// src/IntroPanel.js
import React, { useEffect, useState } from 'react';

function IntroPanel() {
  const [typedText, setTypedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const messages = ["Hello, I'm Felipe", "Olá, sou Felipe", "Ciao, sono Felipe", "Hola, soy Felipe", "नमस्ते, मैं Felipe हूं", "Hallo, ich bin Felipe", "你好，我叫 Felipe",
    "Bonjour, je m'appelle Felipe", "こんにちは私は Felipe", "Cześć, jestem Felipe", "안녕 나는 Felipe", "Γεια είμαι o Felipe", "Привет, я Felipe"]; // The messages to cycle through
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const typingSpeedMs = 100; // Typing speed in milliseconds
  const backspaceSpeedMs = 25; // Backspace speed in milliseconds

  useEffect(() => {
    let timeout;

    // Typing effect
    if (!isDeleting && typedText.length < messages[currentMessageIndex].length) {
      timeout = setTimeout(() => {
        setTypedText(messages[currentMessageIndex].substring(0, typedText.length + 1));
      }, typingSpeedMs);
    } 
    // Backspace effect
    else if (isDeleting && typedText.length > 0) {
      timeout = setTimeout(() => {
        setTypedText(messages[currentMessageIndex].substring(0, typedText.length - 1));
      }, backspaceSpeedMs);
    } 
    // Switch to the next message or loop back to the first message
    else if (typedText.length === 0 && isDeleting) {
      setIsDeleting(false);
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    } 
    // Start deleting after the message is fully typed
    else if (typedText.length === messages[currentMessageIndex].length) {
      timeout = setTimeout(() => {
        setIsDeleting(true);
      }, 2000); // Wait 2 seconds before starting to delete
    }

    return () => clearTimeout(timeout); // Cleanup the timeout if the component unmounts
  }, [typedText, isDeleting, messages, currentMessageIndex]);

  return (
    <div className="flex justify-center items-center h-[66vh] bg-white mb-[300px]">
      <div className="text-center">
        <h1 className="font-alliance-no2 text-5xl lg:text-7xl font-bold relative inline-block">
          {typedText}
          <div className="cursor bg-black h-12 inline-block"></div>
        </h1>
      </div>
    </div>
  );
}

export default IntroPanel;
